<template>
  <div class="form-group form-group-sm">
    <label for="second-param-select" class="small mb-0">{{ $t('table.secondBlockNumber') }}:</label>
    <select id="second-param-select" v-model="selectedProp" class="custom-select-sm custom-select">
      <option :value="null" disabled>{{ $t('table.selectSecondNumberInBlock') }}</option>
      <option :value="1">{{ $t('table.spotActualDuration') }}</option>
      <option :value="2">{{ $t('table.freeForPlacement') }}</option>
    </select>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'onSelect',
  },
  props: {
    showLabel: Boolean,
    inputValue: { type: Number, required: true },
  },
  data() {
    return {};
  },
  computed: {
    selectedProp: {
      get() {
        return this.inputValue;
      },
      set(value) {
        localStorage.setItem('blockSecondParam', value); // save user select for cross-grids and onedays
        this.$emit('onSelect', value);
      },
    },
  },
  methods: {},
};
</script>
